import * as React from "react";

interface LandingProps {
  imgUrl: string;
}

const titleAndMessageList = [
  {title: "Securing Your Digital Transformation", message: "Our team of experts will help you navigate the ever-changing landscape of digital security, ensuring your business stays protected every step of the way."},
  {title: "Proactive Cyber Defense", message: "Our team of experts specializes in proactive cyber defense. We'll help you anticipate and prevent cyber threats, so you can focus on running your business."},
  {title: "Infrastructure Security and Optimization", message: "We specialize in infrastructure security and optimization. Our solutions will help you secure your network, data centers and cloud environments, and optimize them for performance, scalability and compliance"},
  {title: "Complete IT Security and DevOps Services", message: "Our comprehensive approach to IT security and DevOps services will help you stay ahead of the curve and stay protected in an increasingly connected world."},
  {title: "DevOps and Security for a Connected World", message: "In an increasingly connected world, it's essential to have a strong security and DevOps strategy in place. We help businesses of all sizes stay ahead of the curve and protect their digital assets."},
  {title: "Building Secure and Scalable Infrastructure", message: "From cloud migration to compliance and regulatory requirements, we have the experience and expertise to design, implement and maintain a secure and scalable infrastructure for your business."},
  {title: "Cybersecurity for the Modern Business", message: "In today's digital age, cybersecurity is essential for any business. We offer a range of services to help you protect your company and customers against cyber threats."},
  //{title: "End-to-End IT Security Solutions", message: "From threat detection to incident response, our team of experts provides comprehensive IT security solutions to help you safeguard your organization and data."},
  {title: "Protecting Your Business in the Cloud", message: "As more and more companies move to the cloud, it's more important than ever to ensure your data and infrastructure is secure. We specialize in cloud security and compliance."},
  {title: "DevOps and Security Automation", message: "Our DevOps and security experts work together to provide automation solutions that help you securely and efficiently manage your infrastructure and applications."},
  //{title: "Continuous Security Monitoring", message: "With our continuous security monitoring services, you'll be able to detect and respond to threats in real-time, ensuring the ongoing security of your organization."},
]

const Landing = ({ imgUrl }: LandingProps) => {
  // Randomly choose a title and message combo from the titleAndMessageList
  const randomTitleAndMessage = titleAndMessageList[Math.floor(Math.random() * titleAndMessageList.length)];

  return (
    <section
      className="min-w-screen min-h-screen p-0 m-0 flex bg-blend-multiply bg-[#242427]/50 bg-cover"
      style={{
        backgroundImage: "url('/" + imgUrl + "')"
      }}
    >
      <div className="m-auto">
        <h2 className="text-5xl text-center text-white font-bold mb-8">{randomTitleAndMessage.title}</h2>
        <p className="text-center text-3xl text-white mt-8" style={{
          maxWidth: "80%",
          margin: "0 auto"
        }}>{randomTitleAndMessage.message}</p>
      </div>
    </section>
  );
};

export default Landing;
