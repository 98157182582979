import * as React from "react";
import Content from "../contentcontainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";


const Contact = () => {
  return (
    <Content>
      <h2 id="contact" className="text-center text-4xl">
      <FontAwesomeIcon icon={faEnvelope} />  Contact
      </h2>
      <p className="my-10">
        Thank you for visiting our website. At Semaforce, we are dedicated to providing the best possible service to our clients. If you have any questions or would like to learn more about our services, please don't hesitate to contact us.
        You can fill out the contact form on this page and one of our representatives will get back to you as soon as possible.
        Thank you for considering Semaforce for your DevOps and security needs. We look forward to hearing from you.{" "}
      </p>
      <main className="max-w-md mx-auto my-16 p-6 bg-gray-100 rounded-md">
        <form method="post" action="https://public.herotofu.com/v1/28d23870-9b09-11ed-82c7-3d7607318e65">
          <div className="mb-3 pt-0" id="search">
          <input
            type="text"
            placeholder="Your name"
            name="name"
            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full max-width:60px"
            required
          />
          </div>
          <div className="mb-3 pt-0" id="search">
          <input
            type="email"
            placeholder="Email"
            name="email"
            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full max-width:60px"
            required
          />
          </div>
          <div className="mb-3 pt-0">
          <textarea
            placeholder="Your message"
            name="message"
            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
            required
          />
          </div>
          <div className="mb-3 pt-0">
          <button
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="submit"
          >
            Send a message
          </button>
          </div>
        </form>
      </main>
    </Content>
  );
};

export default Contact;
