import * as React from "react";
import Content from "../contentcontainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const About = () => {
  return (
    <Content>
      <h2 id="about" className="text-center text-4xl">
      <FontAwesomeIcon icon={faUser} />  About
      </h2>
      <p className="my-10">
        Welcome to Semaforce, a leading provider of DevOps and security services.
      </p>
      <p className="my-10">
        Our team is made up of highly skilled and experienced professionals with a passion for helping organizations to speed up software development, deployment and delivery while maintaining the security of their systems and data.
      </p>
        <p className="my-10">
        We understand the challenges that organizations face in today's fast-paced business environment and we are dedicated to helping our clients navigate these challenges by implementing best practices.
        </p>
        <p className="my-10">
        Our services include pentests, audits, security consulting, DevOps consulting, automation, infrastructure as code, continuous integration and delivery. We work closely with our clients to understand their unique needs and develop tailored solutions that will help them to achieve their business objectives.
        We are committed to delivering the highest quality services to our clients and we always strive to exceed their expectations. Our team is constantly updating their skills and knowledge to stay ahead of the latest trends and technologies in the industry.
        Thank you for considering Semaforce for your DevOps and security needs. We look forward to working with you.{" "}
      </p>
    </Content>
  );
};

export default About;
