import * as React from "react";

const AboutUs = () => {
  return (
    <div
      className="py-32 w-full bg-zinc-900"
    >

      <div className="m-auto max-w-7xl pl-14">
        <span className="text-white font-bold text-4xl mb-6">
          Ready to learn more about how we can help your organization?
        </span>
        <p className="text-white text-2xl">
          Contact us today to schedule a consultation and discover the benefits of working with us.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
